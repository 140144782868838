import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import preset from './default-preset/preset'

// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'

Vue.use(Vuetify)

const vuetify = new Vuetify({
  preset,
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
  },
})
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdiSvg',
})

export default vuetify

// export default new Vuetify({

// })
