import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import vuetify from './plugins/vuetify'
import App from './App.vue'

import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

import router from './router'
import store from './store'

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)

Vue.use(VueAxios, axios)

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_API

Vue.use(VueCookies)

axios.defaults.headers.common.Authorization = `Bearer ${Vue.$cookies.get('t')}`

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
